<template>
  <div class="view-login auth mb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5 mx-auto">
          <h1 class="text-center mb-5">
            <img class="img-fluid" src="@/assets/images/logo-dark.svg" />
          </h1>
          <form class="card" @submit.prevent="submit">
            <div class="card-body" v-if="!success">
              <h4 class="mb-4">Criar nova senha</h4>

              <form-group
                v-model="password"
                id="password"
                type="password"
                placeholder="Digite a nova senha"
                :errors="errors.password"
              />

              <form-group
                v-model="password_confirm"
                id="password_confirm"
                type="password"
                placeholder="Digite a senha novamente"
                :errors="errors.password_confirm"
              />

              <div class="text-center py-3">
                <button
                  class="btn btn-primary btn-custom-lg"
                  type="submit"
                  :disabled="loading"
                >
                  <loading :show="loading">Salvar</loading>
                </button>
              </div>
            </div>
            <div class="card-body py-5" v-else>
              <h5 class="text-center text-success my-4">
                Senha alterada com sucesso
              </h5>
              <div class="text-center">
                <router-link
                  class="btn btn-primary btn-custom-lg"
                  :to="{ name: 'login' }"
                >
                  Fazer login
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      password: "",
      password_confirm: "",
      errors: {
        password: null,
        password_confirm: null,
        non_field_errors: null
      },
      loading: false,
      success: false
    };
  },
  methods: {
    submit() {
      this.$message.hide();

      if (this.password !== this.password_confirm) {
        this.errors.password_confirm = ["Senha não confere"];
        return;
      }

      this.loading = true;

      this.$store
        .dispatch("user/resetPassword", {
          password: this.password,
          token: this.$route.query.token
        })
        .then(() => {
          this.success = true;
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          } else if (error.response.data.status === "notfound") {
            this.$message.error("Token inválido");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-login {
  width: 100%;
}
</style>
